@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url(https://cdn.jsdelivr.net/npm/firacode@6.2.0/distr/fira_code.css);
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,600;0,700;0,900;1,300&display=swap');

body {
	font-family: 'Montserrat', sans-serif;
	background-color: #000000;
	overflow-x: hidden;
}

.section {
	scroll-snap-align: start;
	height: 100vh;
}

.sectionNumber {
	color: #ffffff;
}
