@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	scroll-behavior: smooth;
}

code {
	font-family: 'Fira Code', monospace;
}

@supports (font-variation-settings: normal) {
	code {
		font-family: 'Fira Code VF', monospace;
	}
}

.companyHandle {
	color: #F0DB4F;
}

.companyHandle {
	overflow: hidden;
	position: relative;
	display: inline-block;
}

.companyHandle::before,
.companyHandle::after {
	content: '';
	position: absolute;
	width: 100%;
	left: 0;
}
.companyHandle::before {
	background-color: #F0DB4F;
	height: 1px;
	bottom: 0;
	transform-origin: 100% 50%;
	transform: scaleX(0);
	transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}
.companyHandle::after {
	content: attr(data-replace);
	height: 100%;
	top: 0;
	transform-origin: 100% 50%;
	transform: translate3d(200%, 0, 0);
	transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
	color: #F0DB4F;
}

.companyHandle:hover::before {
	transform-origin: 0% 50%;
	transform: scaleX(1);
}
.companyHandle:hover::after {
	transform: translate3d(0, 0, 0);
}

.companyHandle span {
	display: inline-block;
	transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.companyHandle:hover span {
	transform: translate3d(-200%, 0, 0);
	cursor: pointer;
}

.companyHandle {
	text-decoration: none;
	color: #fff;
	font-weight: 700;
	vertical-align: top;
}
